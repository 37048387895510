import React from "react"
import PropTypes from "prop-types"

const FooterIntro = ({ title, text, ...props }) => (
  <div {...props}>
    <h2>{ title }</h2>
    <p className="text-sm max-w-xs lg:text-base">{ text }</p>
  </div>
)

FooterIntro.propTypes = {
  title: PropTypes.string,
  text:PropTypes.string,
}

FooterIntro.defaultProps = {
  title: 'Reden wir über Ihr Projekt.',
  text: 'Sie haben den Plan, wir das Know-How – gehen wir es gemeinsam an!',
}

export default FooterIntro
