import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import AniLink from "~components/common/anilink"
import Figure from "~components/common/figure"

const IndexItem = ({ reference, ...props }) => (
  <>
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { relativePath: { glob: "references/**/**/*.(png|jpg)" } }
          ) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 880, maxHeight: 495) {
                    ...GatsbyImageSharpFluid
                  }
                }
                relativePath
              }
            }
          }
        }
      `}
      render={data => (
        <section className="my-xl md:my-2xl" {...props}>
          <div className="lg:flex lg:items-center">
            <div className="gutter lg:w-5/12 xl:w-1/3">
              <header>
                <h2 className="my-0">{reference.title}</h2>
                <div className="text-brown-light">{reference.description}</div>
              </header>
              <AniLink
                className="accessory"
                to={`/referenzen/${reference.slug}`}
              >
                Details
              </AniLink>
            </div>
            <figure className="gutter lg:w-7/12 lg:order-last xl:w-2/3 xl:order-none">
              {data.images.edges.find(({ node }) =>
                node.relativePath.includes(reference.slug + "-01")
              ) && (
                <AniLink className="block" to={`/referenzen/${reference.slug}`}>
                  <Figure
                    fluid={
                      data.images.edges.find(({ node }) =>
                        node.relativePath.includes(reference.slug + "-01")
                      ).node.childImageSharp.fluid
                    }
                    alt={reference.title}
                    title={reference.title}
                  />
                </AniLink>
              )}
            </figure>
          </div>
        </section>
      )}
    />
  </>
)

IndexItem.propTypes = {
  reference: PropTypes.object,
}

export default IndexItem
