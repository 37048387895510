import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Container from "~components/common/container"
import FooterIntro from "~components/global/footer-intro"
import Image from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ContactUs = ({ slug }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: {relativePath: {glob: "team/*.png"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 500, quality: 90, cropFocus: NORTH) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            relativePath
          }
        }
      },
      staff: allTeamJson {
        edges {
          node {
            slug
            image
            name
            position
            email
            phone {
              human
              machine
            }
            intro {
              title
              text
            }
          }
        }
      }
    }
  `)

  let employee

  if (slug) {
    employee = data.staff.edges.find(({node}) => node.slug === slug)
  }

  if (! employee)
    employee = data.staff.edges[Math.floor(Math.random()*data.staff.edges.length)]

  const employeeImage = employee.node.image
    ? data.images.edges.find(({node}) => node.relativePath === employee.node.image)
    : null
  
  return (
    <div>
      <Container standalone className="text-center sm:hidden">
        <FooterIntro />
      </Container>
      <Container>
        <div className="gutter border-b border-brown-darker lg:ml-auto lg:w-5/6">
          <div className="-mx-gutter flex items-center">
            <div className="w-2/3 p-gutter sm:py-lg xl:w-2/5">
              <FooterIntro className="hidden sm:block" title={employee.node.intro.title} text={employee.node.intro.text} />

              <header className="mt-md mb-xs sm:mb-sm">
                <h3 className="my-0">{employee.node.name}</h3>
                <div className="text-sm text-brown-light">{employee.node.position}</div>
              </header>
              <ul>
                <li>
                  <a href={`tel:${employee.node.phone.machine}`} className="hover:text-white">
                    <FontAwesomeIcon icon="phone-alt" fixedWidth className="mr-1" />
                    { employee.node.phone.human }
                  </a>
                </li>
                <li>
                  <a href={`mailto:${employee.node.email}`} className="accessory">
                    E-Mail schreiben
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-1/3 order-first self-end md:px-gutter xl:w-2/5">
              {employeeImage &&
              <figure className="block max-w-sm mx-auto">
                <Image fluid={employeeImage.node.childImageSharp.fluid} alt={employee.node.name} title={employee.node.name} />
              </figure>
              }
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

ContactUs.propTypes = {
  slug: PropTypes.string
}

ContactUs.defaultProps = {
  slug: null
}

export default ContactUs
