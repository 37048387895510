import React from "react"
import { graphql } from "gatsby"
import Layout from "~components/layouts/default"
import ContactUs from "~components/global/contact-us"
import SEO from "~components/common/seo"
import Container from "~components/common/container"
import Reference from "~components/references/index-item"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Page = ({ data }) => (
  <Layout>
    <SEO
      title="Referenzen"
      description="Was wir bauen hält. Und wir halten was wir versprechen. Sehen Sie sich einige unserer Referenzen."
    />
    <Container>
      <div className="xl:w-2/3 xl:mx-auto">
        <div className="gutter my-screen-lg lg:w-3/4">
          <h1 className="max-w-prose-xs">
            Was wir bauen hält. Und was wir versprechen halten wir.
          </h1>
          <p className="max-w-prose">
            Erzählen können wir ja viel. Hier gibt es einen Einblick in einige
            unserer Referenzen.
          </p>
          <p className="max-w-prose">
            Sie wollen mehr sehen?{" "}
            <a
              href={data.info.instagram_link}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white text-brown-lighter"
            >
              <FontAwesomeIcon
                icon={["fab", "instagram"]}
                fixedWidth
                className="fa-lg"
              />
            </a>
            <a
              href={data.info.facebook_link}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white text-brown-lighter"
            >
              <FontAwesomeIcon
                icon={["fab", "facebook-square"]}
                fixedWidth
                className="fa-lg"
              />
            </a>
          </p>
        </div>
      </div>
    </Container>
    <Container>
      {data.references.edges.map(({ node }) => (
        <Reference key={node.slug} reference={node} />
      ))}
    </Container>
    <ContactUs slug="marcel-meier" />
  </Layout>
)

export const query = graphql`
  query {
    info: dataJson {
      instagram_link
      facebook_link
    }
    references: allReferencesJson(sort: { order: ASC, fields: sort }) {
      edges {
        node {
          id
          slug
          title
          description
        }
      }
    }
  }
`

export default Page
